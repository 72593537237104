.nav-pills {
    background-color: $gray-100;
    border: 1px solid $gray-300;

    .nav-link {
        min-width: 132px;
        text-align: center;
        margin-right: 1px;
        border-right: 1px solid $gray-300;
        border-bottom: 2px solid transparent;
        box-shadow: 0 1px 1px $gray-300;
        cursor: pointer;
    }
    & .active {
        border-bottom-color: $primary;
        box-shadow: 0 1px 1px $primary;
    }
}