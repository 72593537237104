@import "variables";
@import "simplebar/dist/simplebar.min.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";
@import "./layout/right-leaf";
@import "./layout/horizontal";
@import "./layout/topbar";
@import "./layout/rtl/rtl";
@import "./components/tab-bar";


/*****************************/
// GENERAL OVERRIDES
/*****************************/

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.form-label {
    margin-bottom: 0.2rem;
    font-size:14px;
}
 .form-control[readonly] {
    background-color: transparent;
    border:0;
    color:#232429;
    font-size:16px;
}

.rdp-form-control{
    &.form-control[readonly] {
        background-color: $white;
        border: 1px solid #fad8bf;
        color:#232429;
        font-size:0.875rem;
        border-top-right-radius: 0.3rem !important;
        border-bottom-right-radius: 0.3rem !important;
        &.is-invalid {
            border: 1px solid #EE1919;
        }
    }
}

.form-select:disabled {
    background-image: none;
}

.form-control-plaintext.form-select:disabled {
    background-color: transparent;
    border: 0;
    padding: 0.375rem 0;
}

.form-control-plaintext {
    font-weight: 600;
}


/*****************************/
// OVERRIDES
/*****************************/

.modal-footer {
    padding: 0.2rem 1rem;
}

.input-group .btn {
    border-radius: 4px;
}

.sidebar_badge {
    padding: 0.35em !important;
    border-radius: 0.25em !important;
}

.datePickerReset button{
    position: absolute !important;
    right: 0;
    height: 38px;
}


.react-datepicker {
    font-size: inherit !important;
    font-family: inherit !important;
    color: #333333 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #333333;
}


/*****************************/
// CUSTOM STILES
/*****************************/

// Height and Width

.full_height {
    height: 100% !important;
}

// Margins

.m-5 {
    margin: 2rem !important;
}
.mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.ms-5 {
    margin-left: 2rem !important;
}
.me-5 {
    margin-right: 2rem !important;
}
.my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.mt-5 {
    margin-top: 2rem !important;
}
.mb-5 {
    margin-bottom: 2rem !important;
}

.m-6 {
    margin: 3rem !important;
}
.mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}
.ms-6 {
    margin-left: 3rem !important;
}
.me-6 {
    margin-right: 3rem !important;
}
.my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.mt-6 {
    margin-top: 3rem !important;
}
.mb-6 {
    margin-bottom: 3rem !important;
}

// Z-index

.z-n1 {
    z-index: -1!important;
}
.z-1 {
    z-index: 1!important;
}
.z-2 {
    z-index: 2!important;
}
.z-3 {
    z-index: 3!important;
}
.z-4 {
    z-index: 4!important;
}
.z-5 {
    z-index: 5!important;
}

// Form

.form-control-plaintext {
    background-image: none;
}


// Titles and typography and components

.section_icon {
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,.1);
    margin-right: 1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 20px;
}

.section_title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding-top: 6px;
    vertical-align: middle;
}

.btn-circle {
    height: 38px;
    width: 38px;
}

.mini-btn-circle {
    height: 32px;
    width: 32px;
}
// Email tab



// Polizza tab



// Home grid

.grid_task_container {
    height: calc(100vh - 220px);
}


// Damaged

.nav-item {    
    .btn-close{
      font-size: 10px;
    }
}



// Skeleton loading

.skeleton {
    animation: skeleton-loading 1.5s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        opacity: 0.3;
        //background-color: hsl(200, 20%, 80%);
    }
    100% {
        opacity: 0.8;
        //background-color: hsl(200, 20%, 95%);
    }
}


//Layer Task SInistro Chiuso
.modal-backdrop-layer {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 94vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop-layer.fade {
  opacity: 0;
}
.modal-backdrop-layer.show {
  opacity: var(--bs-backdrop-opacity);
}

.pac-container {
    z-index: 2147483647 !important;
}