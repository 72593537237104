.fixedTopbar {
  .topbar {
    height: $topbarHeight;
    max-height: $topbarHeight;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .HorizontalTopbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &.contentArea {
    padding-top: $topbarHeight;
  }

  .btn-circle {
    width: 32px;
    height: 32px;
    padding: 6px 0px;
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
  }
}
.ddWidth {
  width: 300px;
}

.mega-dropdown {
  position: static;
  .dropdown-menu {
    padding: 30px;
    width: 100%;
    max-height: 480px;
    overflow: auto;
  }
}

@include media-breakpoint-up(lg) {
  .fixedTopbar {
    .topbar {
      width: 100%;
      left: 0;
    }
  }
  // .isMiniSidebar .fixedTopbar .topbar {
  //   width: 100%;
  //   left: 0;
  // }
  .logo-space {
    min-width: 245px;
    padding-left: 10px;
  }
  .isMiniSidebar .logo-space {
    min-width: 60px;
  }
}
@include media-breakpoint-down(lg) {
  .ddWidth {
    width: 100vw;
    max-width: 400px;
  }
}

.hov-dd:hover,
.hov-dd:focus {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: $border-radius;
}
