#loginContainer {
    background: url('../../../assets/images/bg/bg_login.png') left top #c8d400 no-repeat;
    background-size: auto 100%;
    height: 100vh;
}

#formLoginContainer {
    width: 680px;
}
#formLoginContainer img{
    height: 48px;
}

