.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  .sidebarBox {
    position: relative;
    height: 100%;
    width: $sidebarWidth;
    background: $white;
    border-right: 1px solid $gray-200;
    transition: 0.1s ease-in;
    z-index: 2;
  }
  .fixedSidebar {
    position: fixed;
  }
  .navCaption {
    font-size: 0.775rem;
    padding: 5px 15px;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav-item.activeParent > .nav-link {
    opacity: 1;
  }
  .nav-link {
    color: inherit;
    opacity: 0.65;
    display: flex;
    padding: 12px 5px 12px 12px;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 21px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .lightText {
    color: $white;
  }
  .activeLink .nav-link {
    opacity: 1;
  }
  .subMenu {
    padding: 0;
    list-style: none;
    transition: 0.1s ease-in;
    .nav-link {
      padding: 9px 15px;
    }
    .sidebarIcon {
      visibility: hidden;
    }
  }

/****************************************************************
      NUOVA VERSIONE
****************************************************************/


  .list-group-flush {
    border-bottom: 1px solid $gray-200;;
  
    .list-group-item {
      border-width: 1px 0 0;
      border-color: $gray-200;
      font-weight: bold;
      line-height: 1.25rem;
      padding: 0.5rem;
      cursor: pointer;
      
      &.active {
        border-left-color: $primary;
      }
    }

    .menu_sub_item {
      color: $dark;
      display: block;
      padding: 0.25rem;
      padding-left: 1rem;
      margin-left: 1rem;
      line-height: 1.25rem;
      text-decoration: none;
      border-left: 1px solid transparent;
      cursor: pointer;
      &:hover {
        color: $link-hover-color;
      }
      &.active {
        color: $primary;
        border-left-color: $primary;

      }
    }
  }

}


.sidebarOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}




/*********************************
  HORIZONTAL NAVBAR
*********************************/


.horizontalNav {
  position: fixed;
  width: 100%;
  z-index: 9;

  .nav-item {
    position: relative;
    margin-right: 2px;
    white-space: nowrap;
    
    .btn-close{
      font-size: 10px;
    }

  }
  .nav-link {
    color: inherit;
    opacity: 0.9;
    display: flex;
    margin-top: 4px;
    padding: 12px 8px;
    height: 40px;
    max-height: 40px;
    background-color:  lighten($secondary, 80%);
    border-top: 2px solid $secondary;
    transition: 0.1s ease-in;
    text-align: center;
    span {
      min-width: 72px;
      margin-right: 24px;
    }
    svg {
      width: 21px;
    }
    &.active {
      background-color: $white;
      border-top-color: $primary;
      color: $primary;
    }
    &:hover {
      opacity: 1;
    }
  }
  .firstDD {
    .nav-link {
      padding: 9px 15px;
      &:hover {
        padding-left: 20px;
      }
    }
    svg {
      width: 18px;
    }
  }
  .lightText {
    color: $white;
  }
  .activeLink .nav-link {
    opacity: 1;
  }
}


/****************************************************************
  Media Query
****************************************************************/


@include media-breakpoint-up(md) {
  .horizontalNav {
    &.fixedSidebar {
      position: fixed;
      width: 100%;
    }

    .firstDD {
      position: absolute;
      min-width: 250px;
      padding: 10px;
      display: none;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
    }
    .nav-item:hover .firstDD {
      display: block;
    }
  }
  .HsidebarFixed {
    padding-top: 44px;
  }
}


@include media-breakpoint-down(md) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    z-index: 2;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
  .horizontalNav {
    width: 100%;
    margin-left: 0;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    transition: 0.1s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
    .firstDD {
      display: none;
      &.showfirstDD {
        display: block;
      }
    }
    // .nav-link,
    // .nav-item {
    //   width: 100%;
    // }
    .nav-item:focus .firstDD {
      display: block;
    }
  }
}


@include media-breakpoint-up(md) {
  .isMiniSidebar {
    .sidebarArea {
      width: $miniSidebarWidth;
      .sidebarBox {
        width: $miniSidebarWidth;
        &:hover {
          width: $sidebarWidth;
          .hide-mini {
            display: block;
          }
        }
        .hide-mini {
          display: none;
        }
      }
    }
  }
}

.border-bottom-sidebar {
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.user-name {
  color: #fff;
}

.bg-white {
  .border-bottom-sidebar {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .user-name {
    color: $sidebartext;
  }
}

#horizontal_navigation {
  height: 44px;
  max-height: 44px;
  background-image: url('../../../assets/images/bg/bg_hor_nav.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  background-color: lighten($secondary, 20%);
}


#product_name {
  width: $sidebarWidth;
  padding-top: 10px;
  padding-left: 16px;
  background-color: #D1D74B;
}

#product_name span {
  font-size: 24px;
  font-weight: bolder;
}
